import React from 'react'
import styles from './InitialPage.module.scss'
import { motion } from "framer-motion"
import disactive from '../../image/InitialPage/check.svg'

const titleAnimation = {
    hidden: {
        x: -800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: 800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}


export const InitialPage = ({isOpen}) => {
    return (
        <motion.section 
        className={styles.main} 
        id='initial'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}} 
        >
                <div className={styles.item}>
                    <div className={styles.overlay}>
                        <div className={styles.container}>
                            <motion.h1 className={styles.title} variants={titleAnimation}>УСЛУГИ И АРЕНДА ИЛОСОСА И КАНАЛОПРОМЫВОЧНОЙ ТЕХНИКИ</motion.h1>
                            <motion.div className={styles.box} variants={subtitleAnimation}>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                        <div className={styles.box_text}>
                                            <p className={styles.text}>Илососы с размывом</p>
                                        </div>
                                </div>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                        <div className={styles.box_text}>
                                            <p className={styles.text}>Работаем с НДС и без</p>
                                        </div>
                                </div>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Откачка ила и очистка ЛОС, КОС, КНС, ГНБ</p>
                                    </div>
                                </div>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Объем 5, 7, 10, 12, 15, 18 кубов</p>
                                    </div>
                                </div>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Откачка и утилизация жидких отходов I-V класса опасности</p>
                                    </div>
                                </div>
                            </motion.div>
                            <button className={styles.button} type='button' onClick={isOpen}>вызвать илосос</button>
                            <a className={styles.phone}  href="tel:+79858384070" target='_blank' rel='noopener noreferrer'>вызвать илосос</a>
                        </div>
                    </div>
                </div>  
        </motion.section>
    )
}